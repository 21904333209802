import { kMaxLength } from 'buffer';
import React, { ChangeEvent, FC } from 'react';

type SurveyInputProps = {
    labelText: string;
    value: string;
    placeholder: string;
    type: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    maxLength?: number;
    name?: string;
};

const Input: FC<SurveyInputProps> = ({
    labelText,
    value,
    placeholder,
    type,
    onChange,
    required,
    maxLength,
    name,
}) => {
    return (
        <label className="px-4 py-2">
            <p className="text-sm text-gray-500 mb-1">{required ? labelText + '*' : labelText}</p>
            <input
                className="p-2 border rounded-md text-gray-600 appearance-none outline-none w-full"
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                required={required}
                maxLength={maxLength}
                autoComplete={name}
                name={name}
            />
        </label>
    );
};

export default Input;
