import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import { SettingsProvider } from 'hooks/SettingsContext';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Router>
            <SettingsProvider>
                <App />
            </SettingsProvider>
        </Router>
    </React.StrictMode>
);
reportWebVitals();
