import useWeek from 'hooks/useWeek.hook';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    getFirestore,
    onSnapshot,
    collection,
    query,
    where,
    Timestamp,
    orderBy,
    doc,
    getDoc,
} from 'firebase/firestore';
import 'firebase-service';
import { Appointment, Clinic, ClinicAppointment, Day, Settings } from 'types';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import Form from 'components/form.component';
import { useSettings } from 'hooks/SettingsContext';
import FormSuccessful from './formSuccessful.page';

const BookingPage: FC = () => {
    const { week, currentTime, today, nextWeek, prevWeek, currWeek } = useWeek();
    const [open, setOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(today);
    const [selectedAppointment, setSelectedAppointment] = useState<ClinicAppointment>();
    const [appointments, setAppointments] = useState<ClinicAppointment[]>();
    const [currentClinic, setCurrentClinic] = useState<Clinic>();
    const [selectedClinic, setSelectedClinic] = useState<Clinic>();
    const [clinicsInOrganization, setClinicsInOrganization] = useState<Clinic[]>();
    const navigate = useNavigate();
    const { settings, setSettings } = useSettings();
    const { clinicId } = useParams();

    useEffect(() => {
        if (!clinicId) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinics', clinicId);
        // eslint-disable-next-line promise/catch-or-return
        getDoc(docRef).then((doc) => {
            // eslint-disable-next-line promise/always-return
            if (!doc.exists()) {
                navigate('/');
                return;
            }
        });

        const unsubClinic = onSnapshot(docRef, (snapshotClinic) => {
            const clinic = {
                id: snapshotClinic.id,
                ...snapshotClinic.data(),
            } as Clinic;
            setCurrentClinic(clinic);
            setSelectedClinic(clinic);
        });

        // eslint-disable-next-line consistent-return
        return unsubClinic;
    }, [navigate]);

    useEffect(() => {
        if (!currentClinic) return;
        const db = getFirestore();
        const colRef = collection(db, 'clinics');
        const unsubClinics = onSnapshot(colRef, (snapshotClinics) => {
            const clinics = snapshotClinics.docs
                .map((clinicDoc) => {
                    return { id: clinicDoc.id, ...clinicDoc.data() } as Clinic;
                })
                .filter((clinic) => currentClinic.clinicsInOrganization.includes(clinic.id));

            setClinicsInOrganization(clinics);
        });
        // eslint-disable-next-line consistent-return
        return unsubClinics;
    }, [currentClinic]);
    useEffect(() => {
        if (!selectedClinic) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettings', selectedClinic.id);
        const unsubSettings = onSnapshot(docRef, (snapshotSettings) => {
            setSettings(snapshotSettings.data() as Settings);
        });
        // eslint-disable-next-line consistent-return
        return unsubSettings;
    }, [selectedClinic, setSettings]);
    useEffect(() => {
        if (!selectedClinic) return;
        const stampOfNow = new Timestamp(currentTime / 1000, 0);
        const db = getFirestore();
        const colRef = collection(db, 'appointments');
        const qAppointments = query(
            colRef,
            where('clinic', '==', selectedClinic.id),
            where('published', '==', true),
            where('appointmentAt', '>', stampOfNow),
            where('patient', '==', null),
            orderBy('appointmentAt')
        );
        const unsubAppointments = onSnapshot(qAppointments, (snapshotAppointments) => {
            const appointments = snapshotAppointments.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            }) as ClinicAppointment[];
            setAppointments(appointments);
            if (appointments.length > 0) {
                const nearestAppointmentInTime = appointments[0];
                const dateStringOfNearestAppointment = nearestAppointmentInTime.appointmentAt
                    .toDate()
                    .toDateString();
                const dayOfNearestAppointment = week.weekdays.find(
                    (day) => day.date.toDateString() === dateStringOfNearestAppointment
                );
                setSelectedAppointment(nearestAppointmentInTime);
                if (!dayOfNearestAppointment) return;
                setSelectedDay(dayOfNearestAppointment);
            }
        });

        // eslint-disable-next-line consistent-return
        return unsubAppointments;
    }, [week, selectedClinic]);

    const handleSelectedAppointment = (day: Day, appointment?: ClinicAppointment) => {
        if (!appointment) return;
        setSelectedDay(day);
        setSelectedAppointment(appointment);
    };

    const noBookableSlots = useMemo(() => {
        if (selectedClinic && !appointments) return true;
        return appointments?.length === 0;
    }, [appointments, selectedClinic]);

    const timeSlotStyles = (
        selectedAppointment?: Appointment,
        appointment?: Appointment
    ): string => {
        if (!appointment || !selectedAppointment) return 'bg-gray-100 text-gray-400';
        const isSelected = appointment?.id === selectedAppointment?.id;
        if (isSelected) {
            return 'bg-hs-blue bg-opacity-70 text-white lg:font-bold border border-blue-700';
        }
        return 'bg-green-100 bg-opacity-40 text-green-700 lg:font-bold border border-green-700';
    };
    const handleClose = () => {
        setOpen(!open);
    };
    const getAppointment = (day: Day): ClinicAppointment[] | undefined => {
        const date = moment(day.date).format('YYYY-MM-DD');
        const appointmentsByDate = appointments?.filter((appointment) => appointment.date === date);
        const key = 'startTime';
        const appointmentsByTime = [
            ...new Map(appointmentsByDate?.map((item) => [item[key], item])).values(),
        ];
        return appointmentsByTime;
    };
    return (
        <>
            {appointments && appointments.length > 0 ? (
                <div className="flex flex-col items-start justify-start lg:flex-row lg:items-start lg:justify-center lg:mt-20 mx-[2%] ">
                    <div className="pt-3  text-sm w-full max-h-[80vh] min-h-[50vh] lg:max-h-[90vh] h-auto lg:min-h-[50vh] xl:w-[50%] bg-slate-100">
                        {clinicsInOrganization && clinicsInOrganization?.length > 1 && (
                            <div className="mb-4">
                                <h2 className="font-semibold mb-4">Välj klinik:</h2>
                                <select
                                    className="p-2 border rounded-md text-gray-600 font-semibold outline-none"
                                    onChange={(e) =>
                                        setSelectedClinic(
                                            clinicsInOrganization?.find(
                                                (clinic) => clinic.id === e.currentTarget.value
                                            )
                                        )
                                    }
                                >
                                    {clinicsInOrganization?.map((clinic) => (
                                        <option key={clinic.id} value={clinic.id}>
                                            {clinic.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="flex justify-between mb-4 px-3">
                            <button
                                onClick={prevWeek}
                                disabled={week.weekNumber === moment().week()}
                                className="text-2xl text-hs-blue"
                            >
                                {'<'}
                            </button>
                            <p className="uppercase font-bold text-hs-blue text-end pt-2">{`${week.weekdays[0].month} v.${week.weekNumber}`}</p>

                            <button
                                className="bg-hs-blue bg-opacity-70 text-white px-3 rounded font-thin text-[12px]"
                                onClick={currWeek}
                            >
                                I dag
                            </button>

                            <button onClick={nextWeek} className="text-2xl text-hs-blue px-2">
                                {'>'}
                            </button>
                        </div>
                        <div className="grid grid-cols-7 max-w-full divide-x">
                            {week.weekdays.map((day) => (
                                <div
                                    key={day.weekday}
                                    className="bg-white font-bold w-full px-7 py-3 sticky flex flex-col justify-start items-center shadow-md mb-2"
                                >
                                    <p
                                        className={`${
                                            day.date === selectedDay.date && !noBookableSlots
                                                ? 'text-green-700 bg-opacity-60'
                                                : 'text-gray-800'
                                        }`}
                                    >
                                        {day.weekday}
                                    </p>
                                    <button
                                        disabled={noBookableSlots}
                                        className={`${
                                            day.date === selectedDay.date && !noBookableSlots
                                                ? 'text-green-700 bg-opacity-60'
                                                : 'text-gray-800'
                                        }`}
                                    >
                                        {moment(day.date).format('D')}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="grid grid-cols-7 max-w-full divide-x h-auto max-h-[70vh] xl:max-h-[70vh] overflow-x-scroll mb-2">
                            {week.weekdays.map((day) => (
                                <div
                                    key={day.weekday}
                                    className="flex flex-col items-center gap-2 font-bold "
                                >
                                    {getAppointment(day) &&
                                        getAppointment(day)?.map(
                                            (appointment: ClinicAppointment, i: number) => {
                                                return (
                                                    <button
                                                        key={i}
                                                        className={`${timeSlotStyles(
                                                            selectedAppointment,
                                                            appointment
                                                        )} px-2 py-1 text-[12px] md:text-sm lg:px-3 lg:py-2 rounded-md`}
                                                        onClick={() =>
                                                            handleSelectedAppointment(
                                                                day,
                                                                appointment
                                                            )
                                                        }
                                                    >
                                                        {appointment.startTime}
                                                    </button>
                                                );
                                            }
                                        )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="p-2 lg:p-8 flex w-full lg:w-1/3  flex-col justify-center items-center">
                        {settings?.screeningFields && selectedAppointment && (
                            <Form
                                selectedAppointment={selectedAppointment}
                                handleClose={handleClose}
                            />
                        )}
                        <div>
                            <a className="underline" href={`${window.origin}/${clinicId}/cancel`}>
                                Avboka
                            </a>
                        </div>
                    </div>

                    {open ? <FormSuccessful handleClose={handleClose} /> : null}
                </div>
            ) : (
                <div className="flex justify-center items-center mt-20">
                    <p>Just nu finns ingen tillgänglig tid för bokning</p>
                </div>
            )}
        </>
    );
};

export default BookingPage;
