import React, { FC } from 'react';

type ConfirmationSurveyItemProps = { label: string; value: string };

const ConfirmationSurveyItem: FC<ConfirmationSurveyItemProps> = ({ label, value }) => {
    return (
        <li className="flex gap-4">
            <strong>{label}: </strong>
            {value}
        </li>
    );
};

export default ConfirmationSurveyItem;
