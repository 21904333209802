import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyBWery0TdPRKVyNtZBW1x6P9_as0olnX2w',
    authDomain: 'ehealth-v2.firebaseapp.com',
    databaseURL: 'https://ehealth-v2-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'ehealth-v2',
    storageBucket: 'ehealth-v2.appspot.com',
    messagingSenderId: '1092247891639',
    appId: '1:1092247891639:web:00b0247e1dd65ef029a732',
    measurementId: 'G-C2YZK36D12',
};

initializeApp(firebaseConfig);
