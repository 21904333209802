/* eslint-disable indent */
import React, { FC, useEffect, useMemo, useState } from 'react';
import useQuery from 'hooks/useQuery.hook';
import moment from 'moment';
import { decrypt } from '../crypto';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Clinic, ClinicAppointment, DetailedClinicAppointment, SurveyQuestion } from 'types';
import { useNavigate } from 'react-router-dom';
import ConfirmationSurveyItem from 'components/confirmationSurveyItem.component';
import { useSettings } from 'hooks/SettingsContext';
import { CloseIcon } from '../components/close.icon';

type PROPS = {
    handleClose: () => void;
};

const FormSuccessful: FC<PROPS> = ({ handleClose }) => {
    const query = useQuery();
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState<DetailedClinicAppointment>();
    const { settings } = useSettings();

    useEffect(() => {
        const getAppointment = async (appointmentId: string) => {
            const db = getFirestore();
            const appointmentRef = doc(db, 'appointments', appointmentId);
            const appointmentDoc = await getDoc(appointmentRef);
            if (!appointmentDoc.exists()) {
                navigate('/404');
                return;
            }
            const appointmentData = {
                id: appointmentDoc.id,
                ...appointmentDoc.data(),
            } as ClinicAppointment;

            const clinicRef = doc(db, 'clinics', appointmentData.clinic);
            const clinicDoc = await getDoc(clinicRef);
            const clinicData = clinicDoc.data();
            const detailedAppointmentData: DetailedClinicAppointment = {
                ...appointmentData,
                clinic: { id: clinicDoc.id, ...clinicData } as Clinic,
            };
            setAppointment(detailedAppointmentData);
        };
        const appointmentId = localStorage.getItem('appointment');
        if (!appointmentId) return;
        getAppointment(appointmentId);
    }, [query, navigate]);

    const questionConfirmationData = useMemo(() => {
        if (!appointment?.screening || !settings?.screeningFields) return;
        // eslint-disable-next-line consistent-return
        return settings.screeningFields.map(({ id, information, title }) => {
            const questionConfirmation = {
                id,
                title,
                value: appointment.screening ? `${appointment.screening[title]}` : null,
            };
            return questionConfirmation;
        });
    }, [appointment?.screening]);
    return (
        <>
            <div className="absolute top-0 left-0 w-screen h-screen opacity-[50%] bg-black z-0" />
            {appointment && (
                <div className=" absolute rounded top-10 left-[35vw] bg-white w-[40vw] h-auto p-8 text-sm max-w-screen-sm mx-auto my-auto">
                    <div className="flex  justify-between items-start">
                        <h2 className="font-semibold text-2xl mb-4 text-green-500">
                            Tiden är bokad
                        </h2>
                        <button
                            className="text-white bg-red-500 py-2 px-5 rounded-[20px]"
                            onClick={() => handleClose()}
                        >
                            Stäng
                        </button>
                    </div>
                    <div className="rounded-md bg-white mb-4">
                        <ul className="flex flex-col gap-3">
                            <ConfirmationSurveyItem
                                label="Bokningsnummer"
                                value={appointment.bookingId}
                            />
                            <ConfirmationSurveyItem
                                label="Klinik"
                                value={appointment.clinic.name}
                            />
                            <ConfirmationSurveyItem
                                label="Tid"
                                value={`${moment(appointment.appointmentAt.toDate()).format(
                                    'HH:mm DD MMM YYYY'
                                )}`}
                            />
                            <ConfirmationSurveyItem
                                label="Personnummer"
                                value={decrypt(appointment.patient?.socialSec) ?? '-'}
                            />
                            {questionConfirmationData?.map(({ id, title, value }) => (
                                <ConfirmationSurveyItem
                                    key={id}
                                    label={title}
                                    value={value ?? ''}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};
export default FormSuccessful;
