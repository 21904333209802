import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

const Home = () => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [clinic, setClinic] = useState('');
    const navigate = useNavigate();
    const { clinicId } = useParams();

    useEffect(() => {
        if (clinicId) {
            navigate(`${clinicId}/book`);
        }
    }, []);

    const getClinic = (e: FormEvent) => {
        e.preventDefault();
        if (!clinic) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinics', clinic);
        // eslint-disable-next-line promise/catch-or-return
        getDoc(docRef)
            .then((doc) => {
                // eslint-disable-next-line promise/always-return
                if (!doc.exists()) {
                    setError(!error);
                    setErrorMessage('No clinic found, please try again.');
                    return;
                }
                navigate(`${clinic}/book`);
                return;
            })
            .catch((err) => err);
    };
    return (
        <div className="rounded-md bg-gray-100 flex flex-col justify-center space-y-2 mx-auto w-max bg-gray h-auto  p-12 mt-32">
            <p>Enter username</p>
            <p className="text-red-600">{error && errorMessage}</p>
            <form
                onSubmit={getClinic}
                className="flex flex-col space-y-6 justify-start items-center"
            >
                <input
                    className="border border-1-gray w-[100%] py-2 px-1"
                    type="text"
                    value={clinic}
                    onChange={(e) => setClinic(e.target.value)}
                />
                <button type="submit" className="bg-blue-600 px-5 py-2 rounded w-1/2 text-white">
                    Send
                </button>
            </form>
        </div>
    );
};
export default Home;
