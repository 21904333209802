import React, { FC } from 'react';

const NotFound: FC = () => {
    return (
        <div className="flex flex-col h-[70vh] items-center justify-center">
            <div className="p-10 rounded-lg shadow flex flex-col items-center justify-center bg-gray-100">
                <h1 className="text-6xl font-bold text-blue-900 mb-6">404.</h1>
                <h2 className="mb-4">Den begärda sidan hittades inte.</h2>
            </div>
        </div>
    );
};

export default NotFound;
