import React, { FC, FormEvent, useState, useEffect } from 'react';
import { Timestamp, doc, getFirestore, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import 'firebase-service';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSettings } from 'hooks/SettingsContext';
import { Settings } from 'types';

const CancelBookingPage: FC = () => {
    const { clinicId } = useParams();
    const { settings, setSettings } = useSettings();
    const [bookingId, setBookingId] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!clinicId) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettings', clinicId);
        const unsubSettings = onSnapshot(docRef, (snapshotSettings) => {
            setSettings(snapshotSettings.data() as Settings);
        });
        // eslint-disable-next-line consistent-return
        return unsubSettings;
    }, [clinicId, setSettings]);
    const cancelBooking = async (e: FormEvent) => {
        if (!settings) return;
        e.preventDefault();
        setLoading(true);
        const db = getFirestore();
        const appointmentRef = doc(db, 'appointments', bookingId);
        const docSnap = await getDoc(appointmentRef);
        const appointment = { ...docSnap.data() };
        if (appointment.status && appointment.status !== 'cancelled') {
            await updateDoc(appointmentRef, {
                updatedAt: new Timestamp(Date.now() / 1000, 0),
                arrived: 3,
                status: 'cancelled',
            })
                .then(() => {
                    return axios.post(
                        'https://us-central1-ehealth-v2.cloudfunctions.net/app/api/notification/cancelbookingconfirmation',
                        {
                            clinicName: settings?.config.clinicName,
                            clinicEmail: settings?.config.email,
                            toEmail: appointment.screening.email,
                            bookingId: bookingId,
                            time: `${appointment.date} ${appointment.startTime}`,
                        }
                    );
                })
                .catch((err) => setResponse('Avbokning misslyckades'));
            setLoading(false);
            setResponse('Avbokad');
            return;
        }
        if (appointment.status === 'cancelled') {
            setLoading(false);
            setResponse('Redan avbokad');
            return;
        }
        setLoading(false);
        setResponse('Ingen bokning hittades');
    };

    return (
        <div className="p-2 lg:p-8 flex flex-col justify-center items-center w-1/2 mx-auto gap-3">
            <p>Ange bokningsnummer</p>
            <form onSubmit={cancelBooking} className="flex flex-col gap-5 w-1/3">
                <label>
                    <input
                        onChange={(e) => setBookingId(e.target.value)}
                        className="p-2 border rounded-md border-gray-300  w-full"
                    />
                </label>
                <button
                    className="px-1 py-3 w- bg-blue-400 rounded-md text-white"
                    type="submit"
                    disabled={!bookingId}
                >
                    {loading ? <span className="animate-bounce">.</span> : <p>Avboka</p>}
                </button>
            </form>
            <p>{response}</p>
        </div>
    );
};

export default CancelBookingPage;
