import React, { FC, FormEvent, useEffect, useState } from 'react';
import { encrypt } from '../crypto';
import { doc, getDoc, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { ClinicAppointment, FormData } from 'types';
import Input from './input.component';
import Personnummer from 'personnummer';
import { useSettings } from 'hooks/SettingsContext';
import axios from 'axios';

type SurveyFormProps = {
    selectedAppointment: ClinicAppointment;
    handleClose: () => void;
};

type ScreeningData = {
    [key: string]: string | number;
};
const Form: FC<SurveyFormProps> = ({ selectedAppointment, handleClose }) => {
    const [formData, setFormData] = useState<FormData>({
        socialSec: '1212121212',
        firstName: 'John',
        lastName: 'Doe',
        email: 'abdul.rauf@hyperstack.se',
    });
    const { t } = useTranslation();
    const { settings } = useSettings();
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setIsValid(Personnummer.valid(formData.socialSec));
    }, [formData]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isValid || !selectedAppointment) return;
        setLoading(true);
        const db = getFirestore();
        const appointmentRef = doc(db, 'appointments', selectedAppointment.id);
        const { socialSec, firstName, lastName, price, email } = formData;
        const encryptedSecurityNumber = encrypt(socialSec);
        const screening = {} as ScreeningData;
        settings?.screeningFields.forEach(
            ({ title }) => (screening[title] = formData[title] ?? '')
        );
        await updateDoc(appointmentRef, {
            bookingId: appointmentRef.id,
            patient: {
                socialSec: encryptedSecurityNumber,
                firstName,
                lastName,
            },
            caregiver: {
                title: 'Dr',
                firstName: 'Jane',
                lastName: 'Doe',
            },
            payments: [
                {
                    price: price ?? 100,
                    VATPercentage: '25',
                    VAT: 20,
                },
            ],
            updatedAt: new Timestamp(Date.now() / 1000, 0),
            arrived: 0,
            screening: { ...screening, email },
        })
            .then(() => {
                return axios.post(
                    'https://us-central1-ehealth-v2.cloudfunctions.net/app/api/notification/bookingconfirmation',
                    {
                        clinicName: settings?.config.clinicName,
                        toEmail: formData.email,
                        clinicEmail: settings?.config.email,
                        time: selectedAppointment.date + ' ' + selectedAppointment.startTime,
                        bookingId: appointmentRef.id,
                    }
                );
            })
            .catch((err) => err);
        setLoading(false);
        const appointmentSnap = await getDoc(appointmentRef);
        const updatedAppointment = {
            id: appointmentSnap.id,
            ...appointmentSnap.data(),
            clinic: selectedAppointment.clinic,
        } as unknown as ClinicAppointment;
        localStorage.setItem('appointment', updatedAppointment.id);
        handleClose();
    };

    return (
        <>
            {loading && (
                <div className="absolute flex justify-center items-center top-0 left-0 w-screen h-screen opacity-[50%] bg-black z-0">
                    <h1 className="text-[200px] text-gray-200 animate-bounce">.</h1>
                </div>
            )}
            <form className="grid gap-2 w-full" onSubmit={async (e) => await onSubmit(e)}>
                <div className="grid grid-cols-1 xl:grid-cols-2">
                    <Input
                        type="tel"
                        labelText={t('survey_socialSec_text')}
                        placeholder={t('survey_socialSec_text_placeholder')}
                        value={formData.socialSec ?? ''}
                        onChange={(e) => setFormData({ ...formData, socialSec: e.target.value })}
                        required
                        maxLength={10}
                        name="personnummer"
                    />
                    <Input
                        type="text"
                        name="first-name"
                        labelText={t('patient_first_name_text')}
                        placeholder={t('patient_first_name_text')}
                        value={formData.firstName ?? ''}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        required
                    />
                    <Input
                        type="text"
                        labelText={t('patient_last_name_text')}
                        placeholder={t('patient_last_name_text')}
                        value={formData.lastName ?? ''}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        required
                        name="last-name"
                    />
                    <Input
                        type="email"
                        labelText={t('E-Post')}
                        placeholder={t('example@email.com')}
                        value={formData.email ?? ''}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        required
                        name="email"
                    />
                    {/* <Input
                        type="tel"
                        labelText={t('appointment_price')}
                        placeholder={t('appointment_price_placeholder')}
                        value={formData.price ?? ''}
                        onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        name="price"
                    /> */}
                    {settings?.screeningFields.map(({ id, information, title, type }) => (
                        <Input
                            key={id}
                            type={type}
                            labelText={title}
                            placeholder={information}
                            value={formData[title] ?? ''}
                            onChange={(e) => setFormData({ ...formData, [title]: e.target.value })}
                            name={title}
                        />
                    ))}
                </div>
                <div className="flex justify-center pb-20 pt-8">
                    <button
                        className={`bg-opacity-70 hover:bg-green-700 w-[200px] h-12 rounded-md text-white  ${
                            isValid ? 'bg-hs-blue' : 'bg-gray-300'
                        }`}
                        type="submit"
                    >
                        Boka tid
                    </button>
                </div>
            </form>
        </>
    );
};

export default Form;
