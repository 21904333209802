import React, { FC } from 'react';
import BookingPage from 'pages/booking.page';
import NotFound from 'pages/notFound.page';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home.page';
import CancelBookingPage from 'pages/cancelbooking';

const App: FC = () => {
    return (
        <>
            <Routes>
                <Route path="/:clinicId/book" element={<BookingPage />} />
                <Route path="/:clinicId/cancel" element={<CancelBookingPage />} />
                <Route path="/" element={<Home />} />
                <Route path="/:clinicId" element={<BookingPage />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </>
    );
};

export default App;
